import { int, pad } from '../utils';

const doNothing = function () {
  return undefined;
};
export const monthToStr = function (monthNumber, shorthand, locale) {
  return locale.months[shorthand ? 'shorthand' : 'longhand'][monthNumber];
};
export const revFormat = {
  D: doNothing,
  F (dateObj, monthName, locale) {
    dateObj.setMonth(locale.months.longhand.indexOf(monthName));
  },
  G (dateObj, hour) {
    dateObj.setHours((dateObj.getHours() >= 12 ? 12 : 0) + parseFloat(hour));
  },
  H (dateObj, hour) {
    dateObj.setHours(parseFloat(hour));
  },
  J (dateObj, day) {
    dateObj.setDate(parseFloat(day));
  },
  K (dateObj, amPM, locale) {
    dateObj.setHours((dateObj.getHours() % 12) +
        12 * int(new RegExp(locale.amPM[1], 'i').test(amPM)));
  },
  M (dateObj, shortMonth, locale) {
    dateObj.setMonth(locale.months.shorthand.indexOf(shortMonth));
  },
  S (dateObj, seconds) {
    dateObj.setSeconds(parseFloat(seconds));
  },
  U (_, unixSeconds) {
    return new Date(parseFloat(unixSeconds) * 1000);
  },
  W (dateObj, weekNum, locale) {
    const weekNumber = parseInt(weekNum);
    const date = new Date(dateObj.getFullYear(), 0, 2 + (weekNumber - 1) * 7, 0, 0, 0, 0);
    date.setDate(date.getDate() - date.getDay() + locale.firstDayOfWeek);
    return date;
  },
  Y (dateObj, year) {
    dateObj.setFullYear(parseFloat(year));
  },
  Z (_, ISODate) {
    return new Date(ISODate);
  },
  d (dateObj, day) {
    dateObj.setDate(parseFloat(day));
  },
  h (dateObj, hour) {
    dateObj.setHours((dateObj.getHours() >= 12 ? 12 : 0) + parseFloat(hour));
  },
  i (dateObj, minutes) {
    dateObj.setMinutes(parseFloat(minutes));
  },
  j (dateObj, day) {
    dateObj.setDate(parseFloat(day));
  },
  l: doNothing,
  m (dateObj, month) {
    dateObj.setMonth(parseFloat(month) - 1);
  },
  n (dateObj, month) {
    dateObj.setMonth(parseFloat(month) - 1);
  },
  s (dateObj, seconds) {
    dateObj.setSeconds(parseFloat(seconds));
  },
  u (_, unixMillSeconds) {
    return new Date(parseFloat(unixMillSeconds));
  },
  w: doNothing,
  y (dateObj, year) {
    dateObj.setFullYear(2000 + parseFloat(year));
  },
};
export const tokenRegex = {
  D: '',
  F: '',
  G: '(\\d\\d|\\d)',
  H: '(\\d\\d|\\d)',
  J: '(\\d\\d|\\d)\\w+',
  K: '',
  M: '',
  S: '(\\d\\d|\\d)',
  U: '(.+)',
  W: '(\\d\\d|\\d)',
  Y: '(\\d{4})',
  Z: '(.+)',
  d: '(\\d\\d|\\d)',
  h: '(\\d\\d|\\d)',
  i: '(\\d\\d|\\d)',
  j: '(\\d\\d|\\d)',
  l: '',
  m: '(\\d\\d|\\d)',
  n: '(\\d\\d|\\d)',
  s: '(\\d\\d|\\d)',
  u: '(.+)',
  w: '(\\d\\d|\\d)',
  y: '(\\d{2})',
};
export const formats = {
  Z (date) {
    return date.toISOString();
  },
  D (date, locale, options) {
    return locale.weekdays.shorthand[formats.w(date, locale, options)];
  },
  F (date, locale, options) {
    return monthToStr(formats.n(date, locale, options) - 1, false, locale);
  },
  G (date, locale, options) {
    return pad(formats.h(date, locale, options));
  },
  H (date) {
    return pad(date.getHours());
  },
  J (date, locale) {
    return locale.ordinal !== undefined
      ? date.getDate() + locale.ordinal(date.getDate())
      : date.getDate();
  },
  K (date, locale) {
    return locale.amPM[int(date.getHours() > 11)];
  },
  M (date, locale) {
    return monthToStr(date.getMonth(), true, locale);
  },
  S (date) {
    return pad(date.getSeconds());
  },
  U (date) {
    return date.getTime() / 1000;
  },
  W (date, _, options) {
    return options.getWeek(date);
  },
  Y (date) {
    return pad(date.getFullYear(), 4);
  },
  d (date) {
    return pad(date.getDate());
  },
  h (date) {
    return (date.getHours() % 12 ? date.getHours() % 12 : 12);
  },
  i (date) {
    return pad(date.getMinutes());
  },
  j (date) {
    return date.getDate();
  },
  l (date, locale) {
    return locale.weekdays.longhand[date.getDay()];
  },
  m (date) {
    return pad(date.getMonth() + 1);
  },
  n (date) {
    return date.getMonth() + 1;
  },
  s (date) {
    return date.getSeconds();
  },
  u (date) {
    return date.getTime();
  },
  w (date) {
    return date.getDay();
  },
  y (date) {
    return String(date.getFullYear()).substring(2);
  },
};
