import { defaults } from '../types/options';
import { english } from '../l10n/default';
import { formats, revFormat, tokenRegex } from './formatting';

export const createDateFormatter = function (_a) {
  const _b = _a.config;
  const config = _b === undefined ? defaults : _b;
  const _c = _a.l10n;
  const l10n = _c === undefined ? english : _c;
  const _d = _a.isMobile;
  const isMobile = _d === undefined ? false : _d;
  return function (dateObj, frmt, overrideLocale) {
    const locale = overrideLocale || l10n;
    if (config.formatDate !== undefined && !isMobile) {
      return config.formatDate(dateObj, frmt, locale);
    }
    return frmt
      .split('')
      .map(function (c, i, arr) {
        return formats[c] && arr[i - 1] !== '\\'
          ? formats[c](dateObj, locale, config)
          : c !== '\\'
            ? c
            : '';
      })
      .join('');
  };
};
export const createDateParser = function (_a) {
  const _b = _a.config;
  const config = _b === undefined ? defaults : _b;
  const _c = _a.l10n;
  const l10n = _c === undefined ? english : _c;
  return function (date, givenFormat, timeless, customLocale) {
    if (date !== 0 && !date) {
      return undefined;
    }
    const locale = customLocale || l10n;
    let parsedDate;
    const dateOrig = date;
    if (date instanceof Date) {
      parsedDate = new Date(date.getTime());
    } else if (typeof date !== 'string' &&
        date.toFixed !== undefined) {
      parsedDate = new Date(date);
    } else if (typeof date === 'string') {
      const format = givenFormat || (config || defaults).dateFormat;
      const datestr = String(date).trim();
      if (datestr === 'today') {
        parsedDate = new Date();
        timeless = true;
      } else if (config && config.parseDate) {
        parsedDate = config.parseDate(date, format);
      } else if (/Z$/.test(datestr) ||

          /GMT$/.test(datestr)) {
        parsedDate = new Date(date);
      } else {
        let matched;
        const ops = [];
        for (let i = 0, matchIndex = 0, regexStr = ''; i < format.length; i++) {
          const token = format[i];
          const isBackSlash = token === '\\';
          const escaped = format[i - 1] === '\\' || isBackSlash;
          if (tokenRegex[token] && !escaped) {
            regexStr += tokenRegex[token];
            const match = new RegExp(regexStr).exec(date);
            if (match && (matched = true)) {
              ops[token !== 'Y' ? 'push' : 'unshift']({
                fn: revFormat[token],
                val: match[++matchIndex],
              });
            }
          } else if (!isBackSlash) {
            regexStr += '.';
          }
        }
        parsedDate =
            !config || !config.noCalendar
              ? new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0)
              : new Date(new Date().setHours(0, 0, 0, 0));
        ops.forEach(function (_a) {
          const fn = _a.fn;
          const val = _a.val;
          return (parsedDate = fn(parsedDate, val, locale) || parsedDate);
        });
        parsedDate = matched ? parsedDate : undefined;
      }
    }
    if (!(parsedDate instanceof Date && !isNaN(parsedDate.getTime()))) {
      config.errorHandler(new Error('Invalid date provided: ' + dateOrig));
      return undefined;
    }
    if (timeless === true) {
      parsedDate.setHours(0, 0, 0, 0);
    }
    return parsedDate;
  };
};

export function compareDates (date1, date2, timeless) {
  if (timeless === undefined) {
    timeless = true;
  }
  if (timeless !== false) {
    return (new Date(date1.getTime()).setHours(0, 0, 0, 0) -
        new Date(date2.getTime()).setHours(0, 0, 0, 0));
  }
  return date1.getTime() - date2.getTime();
}

export function compareTimes (date1, date2) {
  return (3600 * (date1.getHours() - date2.getHours()) +
      60 * (date1.getMinutes() - date2.getMinutes()) +
      date1.getSeconds() -
      date2.getSeconds());
}

export const isBetween = function (ts, ts1, ts2) {
  return ts > Math.min(ts1, ts2) && ts < Math.max(ts1, ts2);
};
export const calculateSecondsSinceMidnight = function (hours, minutes, seconds) {
  return hours * 3600 + minutes * 60 + seconds;
};
export const parseSeconds = function (secondsSinceMidnight) {
  const hours = Math.floor(secondsSinceMidnight / 3600);
  const minutes = (secondsSinceMidnight - hours * 3600) / 60;
  return [hours, minutes, secondsSinceMidnight - hours * 3600 - minutes * 60];
};
export const duration = {
  DAY: 86400000,
};

export function getDefaultHours (config) {
  let hours = config.defaultHour;
  let minutes = config.defaultMinute;
  let seconds = config.defaultSeconds;
  if (config.minDate !== undefined) {
    const minHour = config.minDate.getHours();
    const minMinutes = config.minDate.getMinutes();
    const minSeconds = config.minDate.getSeconds();
    if (hours < minHour) {
      hours = minHour;
    }
    if (hours === minHour && minutes < minMinutes) {
      minutes = minMinutes;
    }
    if (hours === minHour && minutes === minMinutes && seconds < minSeconds) {
      seconds = config.minDate.getSeconds();
    }
  }
  if (config.maxDate !== undefined) {
    const maxHr = config.maxDate.getHours();
    const maxMinutes = config.maxDate.getMinutes();
    hours = Math.min(hours, maxHr);
    if (hours === maxHr) {
      minutes = Math.min(maxMinutes, minutes);
    }
    if (hours === maxHr && minutes === maxMinutes) {
      seconds = config.maxDate.getSeconds();
    }
  }
  return { hours, minutes, seconds };
}
