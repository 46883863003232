export function toggleClass (elem, className, bool) {
  if (bool === true) { return elem.classList.add(className); }
  elem.classList.remove(className);
}
export function createElement (tag, className, content) {
  const e = window.document.createElement(tag);
  className = className || '';
  content = content || '';
  e.className = className;
  if (content !== undefined) { e.textContent = content; }
  return e;
}
export function clearNode (node) {
  while (node.firstChild) { node.removeChild(node.firstChild); }
}
export function findParent (node, condition) {
  if (condition(node)) { return node; } else if (node.parentNode) { return findParent(node.parentNode, condition); }
  return undefined;
}
export function createNumberInput (inputClassName, opts) {
  const wrapper = createElement('div', 'numInputWrapper'); const numInput = createElement('input', 'numInput ' + inputClassName); const arrowUp = createElement('span', 'arrowUp'); const arrowDown = createElement('span', 'arrowDown');
  if (!navigator.userAgent.includes('MSIE 9.0')) {
    numInput.type = 'number';
  } else {
    numInput.type = 'text';
    numInput.pattern = '\\d*';
  }
  if (opts !== undefined) {
    for (const key in opts) {
      if (!['upArrow', 'downArrow'].includes(key)) {
        numInput.setAttribute(key, opts[key]);
      } else if (key === 'upArrow') {
        arrowUp.innerHTML = opts[key];
      } else {
        arrowDown.innerHTML = opts[key];
      }
    }
  }
  wrapper.appendChild(numInput);
  wrapper.appendChild(arrowUp);
  wrapper.appendChild(arrowDown);
  return wrapper;
}
export function getEventTarget (event) {
  try {
    if (typeof event.composedPath === 'function') {
      const path = event.composedPath();
      return path[0];
    }
    return event.target;
  } catch (error) {
    return event.target;
  }
}
