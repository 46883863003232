export const HOOKS = [
  'onChange',
  'onClose',
  'onDayCreate',
  'onDestroy',
  'onKeyDown',
  'onMonthChange',
  'onOpen',
  'onParseConfig',
  'onReady',
  'onValueUpdate',
  'onYearChange',
  'onPreCalendarPosition',
];
export const defaults = {
  _disable: [],
  allowInput: false,
  allowInvalidPreload: false,
  altFormat: 'F j, Y',
  altInput: false,
  altInputClass: 'form-control input',
  animate: typeof window === 'object' &&
      !window.navigator.userAgent.includes('MSIE'),
  ariaDateFormat: 'F j, Y',
  autoFillDefaultTime: true,
  clickOpens: true,
  closeOnSelect: true,
  conjunction: ', ',
  dateFormat: 'Y-m-d',
  defaultHour: 12,
  defaultMinute: 0,
  defaultSeconds: 0,
  disable: [],
  disableMobile: false,
  enableSeconds: false,
  enableTime: false,
  errorHandler (err) {
    // eslint-disable-next-line no-console
    return typeof console !== 'undefined' && console.warn(err);
  },
  getWeek (givenDate) {
    const date = new Date(givenDate.getTime());
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    const week1 = new Date(date.getFullYear(), 0, 4);
    return (1 +
        Math.round(((date.getTime() - week1.getTime()) / 86400000 -
                3 +
                ((week1.getDay() + 6) % 7)) /
            7));
  },
  hourIncrement: 1,
  ignoredFocusElements: [],
  inline: false,
  locale: 'default',
  minuteIncrement: 5,
  mode: 'single',
  monthSelectorType: 'dropdown',
  nextArrow: '<svg version=\'1.1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' viewBox=\'0 0 17 17\'><g></g><path d=\'M13.207 8.472l-7.854 7.854-0.707-0.707 7.146-7.146-7.146-7.148 0.707-0.707 7.854 7.854z\' /></svg>',
  noCalendar: false,
  now: new Date(),
  onChange: [],
  onClose: [],
  onDayCreate: [],
  onDestroy: [],
  onKeyDown: [],
  onMonthChange: [],
  onOpen: [],
  onParseConfig: [],
  onReady: [],
  onValueUpdate: [],
  onYearChange: [],
  onPreCalendarPosition: [],
  plugins: [],
  position: 'auto',
  positionElement: undefined,
  prevArrow: '<svg version=\'1.1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' viewBox=\'0 0 17 17\'><g></g><path d=\'M5.207 8.471l7.146 7.147-0.707 0.707-7.853-7.854 7.854-7.853 0.707 0.707-7.147 7.146z\' /></svg>',
  shorthandCurrentMonth: false,
  showMonths: 1,
  static: false,
  time_24hr: false,
  weekNumbers: false,
  wrap: false,
  upArrow: '',
  downArrow: '',
};
