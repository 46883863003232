export const pad = function (number, length) {
  if (length === undefined) {
    length = 2;
  }
  return ('000' + number).slice(length * -1);
};
export const int = function (bool) {
  return (bool === true ? 1 : 0);
};

export function debounce (fn, wait) {
  let t;
  return function () {
    const _this = this;
    const args = arguments;
    clearTimeout(t);
    t = setTimeout(function () {
      return fn.apply(_this, args);
    }, wait);
  };
}

export const arrayify = function (obj) {
  return Array.isArray(obj) ? obj : [obj];
};
