/*
* Originally forked from: https://github.com/flatpickr/flatpickr
*/

import { defaults as defaultOptions, HOOKS } from './types/options';
import English from './l10n/default';
import { arrayify, debounce, int, pad } from './utils';
import { clearNode, createElement, createNumberInput, findParent, getEventTarget, toggleClass } from './utils/dom';
import { calculateSecondsSinceMidnight, compareDates, createDateFormatter, createDateParser, duration, getDefaultHours, isBetween, parseSeconds } from './utils/dates';
import { monthToStr, tokenRegex } from './utils/formatting';

let __assign = (this && this.__assign) || function () {
  __assign = Object.assign;
  return __assign.apply(this, arguments);
};
const __spreadArrays = (this && this.__spreadArrays) || function () {
  // eslint-disable-next-line no-var
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }
  // eslint-disable-next-line no-var,no-redeclare
  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (let a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }
  return r;
};

const DEBOUNCED_CHANGE_MS = 300;

function FlatpickrInstance (element, instanceConfig) {
  const self = {
    config: __assign(__assign({}, defaultOptions), flatpickr.defaultConfig), l10n: English,
  };
  self.parseDate = createDateParser({ config: self.config, l10n: self.l10n });
  self._handlers = [];
  self.pluginElements = [];
  self.loadedPlugins = [];
  self._bind = bind;
  self._setHoursFromDate = setHoursFromDate;
  self._positionCalendar = positionCalendar;
  self.changeMonth = changeMonth;
  self.changeYear = changeYear;
  self.clear = clear;
  self.close = close;
  self.closeMonthPicker = closeMonthPicker;
  self.onMouseOver = onMouseOver;
  self._createElement = createElement;
  self.createDay = createDay;
  self.destroy = destroy;
  self.isEnabled = isEnabled;
  self.jumpToDate = jumpToDate;
  self.updateValue = updateValue;
  self.open = open;
  self.openMonthPicker = openMonthPicker;
  self.redraw = redraw;
  self.set = set;
  self.setDate = setDate;
  self.toggle = toggle;
  self.toggleMonthPicker = toggleMonthPicker;

  function setupHelperFunctions () {
    self.utils = {
      getDaysInMonth (month, yr) {
        if (month === undefined) {
          month = self.currentMonth;
        }
        if (yr === undefined) {
          yr = self.currentYear;
        }
        if (month === 1 && ((yr % 4 === 0 && yr % 100 !== 0) || yr % 400 === 0)) {
          return 29;
        }
        return self.l10n.daysInMonth[month];
      },
    };
  }

  function init () {
    self.element = self.input = element;
    self.isOpen = false;
    self.isMonthPickerOpen = false;
    parseConfig();
    setupLocale();
    setupInputs();
    setupDates();
    setupHelperFunctions();
    if (!self.isMobile) {
      build();
    }
    bindEvents();
    if (self.selectedDates.length || self.config.noCalendar) {
      if (self.config.enableTime) {
        setHoursFromDate(self.config.noCalendar ? self.latestSelectedDateObj : undefined);
      }
      updateValue(false);
    }
    setCalendarWidth();
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (!self.isMobile && isSafari) {
      positionCalendar();
    }
    triggerEvent('onReady');
  }

  function getClosestActiveElement () {
    let _a;
    return (((_a = self.calendarContainer) === null || _a === undefined ? undefined : _a.getRootNode()).activeElement || document.activeElement);
  }

  function bindToInstance (fn) {
    return fn.bind(self);
  }

  function setCalendarWidth () {
    const config = self.config;
    // eslint-disable-next-line no-empty
    if (config.weekNumbers === false && config.showMonths === 1) {

    } else if (config.noCalendar !== true) {
      window.requestAnimationFrame(function () {
        if (self.calendarContainer !== undefined) {
          self.calendarContainer.style.visibility = 'hidden';
          self.calendarContainer.style.display = 'block';
        }
        if (self.daysContainer !== undefined) {
          const daysWidth = (self.days.offsetWidth + 1) * config.showMonths;
          self.daysContainer.style.width = daysWidth + 'px';
          self.calendarContainer.style.width = daysWidth + (self.weekWrapper !== undefined ? self.weekWrapper.offsetWidth : 0) + 'px';
          self.calendarContainer.style.removeProperty('visibility');
          self.calendarContainer.style.removeProperty('display');
        }
      });
    }
  }

  function updateTime (e) {
    if (self.selectedDates.length === 0) {
      const defaultDate = self.config.minDate === undefined || compareDates(new Date(), self.config.minDate) >= 0 ? new Date() : new Date(self.config.minDate.getTime());
      const defaults = getDefaultHours(self.config);
      defaultDate.setHours(defaults.hours, defaults.minutes, defaults.seconds, defaultDate.getMilliseconds());
      self.selectedDates = [defaultDate];
      self.latestSelectedDateObj = defaultDate;
    }
    if (e !== undefined && e.type !== 'blur') {
      timeWrapper(e);
    }
    const prevValue = self._input.value;
    setHoursFromInputs();
    updateValue();
    if (self._input.value !== prevValue) {
      self._debouncedChange();
    }
  }

  function ampm2military (hour, amPM) {
    return (hour % 12) + 12 * int(amPM === self.l10n.amPM[1]);
  }

  function military2ampm (hour) {
    switch (hour % 24) {
      case 0:
      case 12:
        return 12;
      default:
        return hour % 12;
    }
  }

  function setHoursFromInputs () {
    if (self.hourElement === undefined || self.minuteElement === undefined) {
      return;
    }
    let hours = (parseInt(self.hourElement.value.slice(-2), 10) || 0) % 24;
    let minutes = (parseInt(self.minuteElement.value, 10) || 0) % 60;
    let seconds = self.secondElement !== undefined ? (parseInt(self.secondElement.value, 10) || 0) % 60 : 0;
    if (self.amPM !== undefined) {
      hours = ampm2military(hours, self.amPM.textContent);
    }
    const limitMinHours = self.config.minTime !== undefined || (self.config.minDate && self.minDateHasTime && self.latestSelectedDateObj && compareDates(self.latestSelectedDateObj, self.config.minDate, true) === 0);
    const limitMaxHours = self.config.maxTime !== undefined || (self.config.maxDate && self.maxDateHasTime && self.latestSelectedDateObj && compareDates(self.latestSelectedDateObj, self.config.maxDate, true) === 0);
    if (self.config.maxTime !== undefined && self.config.minTime !== undefined && self.config.minTime > self.config.maxTime) {
      const minBound = calculateSecondsSinceMidnight(self.config.minTime.getHours(), self.config.minTime.getMinutes(), self.config.minTime.getSeconds());
      const maxBound = calculateSecondsSinceMidnight(self.config.maxTime.getHours(), self.config.maxTime.getMinutes(), self.config.maxTime.getSeconds());
      const currentTime = calculateSecondsSinceMidnight(hours, minutes, seconds);
      if (currentTime > maxBound && currentTime < minBound) {
        const result = parseSeconds(minBound);
        hours = result[0];
        minutes = result[1];
        seconds = result[2];
      }
    } else {
      if (limitMaxHours) {
        const maxTime = self.config.maxTime !== undefined ? self.config.maxTime : self.config.maxDate;
        hours = Math.min(hours, maxTime.getHours());
        if (hours === maxTime.getHours()) {
          minutes = Math.min(minutes, maxTime.getMinutes());
        }
        if (minutes === maxTime.getMinutes()) {
          seconds = Math.min(seconds, maxTime.getSeconds());
        }
      }
      if (limitMinHours) {
        const minTime = self.config.minTime !== undefined ? self.config.minTime : self.config.minDate;
        hours = Math.max(hours, minTime.getHours());
        if (hours === minTime.getHours() && minutes < minTime.getMinutes()) {
          minutes = minTime.getMinutes();
        }
        if (minutes === minTime.getMinutes()) {
          seconds = Math.max(seconds, minTime.getSeconds());
        }
      }
    }
    setHours(hours, minutes, seconds);
  }

  function setHoursFromDate (dateObj) {
    const date = dateObj || self.latestSelectedDateObj;
    if (date && date instanceof Date) {
      setHours(date.getHours(), date.getMinutes(), date.getSeconds());
    }
  }

  function setHours (hours, minutes, seconds) {
    if (self.latestSelectedDateObj !== undefined) {
      self.latestSelectedDateObj.setHours(hours % 24, minutes, seconds || 0, 0);
    }
    if (!self.hourElement || !self.minuteElement || self.isMobile) {
      return;
    }
    self.hourElement.value = pad(!self.config.time_24hr ? ((12 + hours) % 12) + 12 * int(hours % 12 === 0) : hours);
    self.minuteElement.value = pad(minutes);
    if (self.amPM !== undefined) {
      self.amPM.textContent = self.l10n.amPM[int(hours >= 12)];
    }
    if (self.secondElement !== undefined) {
      self.secondElement.value = pad(seconds);
    }
  }

  function onYearInput (event) {
    const eventTarget = getEventTarget(event);
    const year = parseInt(eventTarget.value) + (event.delta || 0);
    if (year / 1000 > 1 || (event.key === 'Enter' && !/[^\d]/.test(year.toString()))) {
      changeYear(year);
    }
  }

  function bind (element, event, handler, options) {
    if (Array.isArray(event)) {
      return event.forEach(function (ev) {
        return bind(element, ev, handler, options);
      });
    }
    if (Array.isArray(element)) {
      return element.forEach(function (el) {
        return bind(el, event, handler, options);
      });
    }
    element.addEventListener(event, handler, options);
    self._handlers.push({
      remove () {
        return element.removeEventListener(event, handler, options);
      },
    });
  }

  function triggerChange () {
    triggerEvent('onChange');
  }

  function bindEvents () {
    if (self.config.wrap) {
      ['open', 'close', 'toggle', 'clear'].forEach(function (evt) {
        Array.prototype.forEach.call(self.element.querySelectorAll('[data-' + evt + ']'), function (el) {
          return bind(el, 'click', self[evt]);
        });
      });
    }
    if (self.isMobile) {
      setupMobile();
      return;
    }
    const debouncedResize = debounce(onResize, 50);
    self._debouncedChange = debounce(triggerChange, DEBOUNCED_CHANGE_MS);
    if (self.daysContainer && !/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      bind(self.daysContainer, 'mouseover', function (e) {
        if (self.config.mode === 'range') {
          onMouseOver(getEventTarget(e));
        }
      });
    }
    bind(self._input, 'keydown', onKeyDown);
    if (self.calendarContainer !== undefined) {
      bind(self.calendarContainer, 'keydown', onKeyDown);
    }
    if (!self.config.inline && !self.config.static) {
      bind(window, 'resize', debouncedResize);
    }
    if (window.ontouchstart !== undefined) {
      bind(window.document, 'touchstart', documentClick);
    } else {
      bind(window.document, 'mousedown', documentClick);
    }
    bind(window.document, 'focus', documentClick, { capture: true });
    if (self.config.clickOpens === true) {
      bind(self._input, 'focus', self.open);
      bind(self._input, 'click', self.open);
    }
    if (self.daysContainer !== undefined) {
      bind(self.monthNav, 'click', onMonthNavClick);
      bind(self.monthNav, ['keyup', 'increment'], onYearInput);
      bind(self.daysContainer, 'click', selectDate);
    }
    if (self.timeContainer !== undefined && self.minuteElement !== undefined && self.hourElement !== undefined) {
      const selText = function (e) {
        return getEventTarget(e).select();
      };
      bind(self.timeContainer, ['increment'], updateTime);
      bind(self.timeContainer, 'blur', updateTime, { capture: true });
      bind(self.timeContainer, 'click', timeIncrement);
      bind([self.hourElement, self.minuteElement], ['focus', 'click'], selText);
      if (self.secondElement !== undefined) {
        bind(self.secondElement, 'focus', function () {
          return self.secondElement && self.secondElement.select();
        });
      }
      if (self.amPM !== undefined) {
        bind(self.amPM, 'click', function (e) {
          updateTime(e);
        });
      }
    }
    if (self.config.allowInput) {
      bind(self._input, 'blur', onBlur);
    }
  }

  function jumpToDate (jumpDate, triggerChange) {
    const jumpTo = jumpDate !== undefined ? self.parseDate(jumpDate) : self.latestSelectedDateObj || (self.config.minDate && self.config.minDate > self.now ? self.config.minDate : self.config.maxDate && self.config.maxDate < self.now ? self.config.maxDate : self.now);
    const oldYear = self.currentYear;
    const oldMonth = self.currentMonth;
    try {
      if (jumpTo !== undefined) {
        self.currentYear = jumpTo.getFullYear();
        self.currentMonth = jumpTo.getMonth();
      }
    } catch (e) {
      e.message = 'Invalid date supplied: ' + jumpTo;
      self.config.errorHandler(e);
    }
    if (triggerChange && self.currentYear !== oldYear) {
      triggerEvent('onYearChange');
      buildMonthSwitch();
    }
    if (triggerChange && (self.currentYear !== oldYear || self.currentMonth !== oldMonth)) {
      triggerEvent('onMonthChange');
    }
    self.redraw();
  }

  function timeIncrement (e) {
    const eventTarget = getEventTarget(e);
    if (~eventTarget.className.indexOf('arrow')) {
      incrementNumInput(e, eventTarget.classList.contains('arrowUp') ? 1 : -1);
    }
  }

  function incrementNumInput (e, delta, inputElem) {
    const target = e && getEventTarget(e);
    const input = inputElem || (target && target.parentNode && target.parentNode.firstChild);
    const event = createEvent('increment');
    event.delta = delta;
    input && input.dispatchEvent(event);
  }

  function build () {
    const fragment = window.document.createDocumentFragment();
    self.calendarContainer = createElement('div', 'flatpickr-calendar');
    self.calendarContainer.tabIndex = -1;
    if (!self.config.noCalendar) {
      fragment.appendChild(buildMonthNav());
      self.innerContainer = createElement('div', 'flatpickr-innerContainer');
      if (self.config.weekNumbers) {
        const _a = buildWeeks();
        const weekWrapper = _a.weekWrapper;
        const weekNumbers = _a.weekNumbers;
        self.innerContainer.appendChild(weekWrapper);
        self.weekNumbers = weekNumbers;
        self.weekWrapper = weekWrapper;
      }
      self.rContainer = createElement('div', 'flatpickr-rContainer');
      self.rContainer.appendChild(buildWeekdays());
      if (!self.daysContainer) {
        self.daysContainer = createElement('div', 'flatpickr-days');
        self.daysContainer.tabIndex = -1;
      }
      buildDays();
      self.rContainer.appendChild(self.daysContainer);
      self.innerContainer.appendChild(self.rContainer);
      fragment.appendChild(self.innerContainer);
    }
    if (self.config.enableTime) {
      fragment.appendChild(buildTime());
    }
    toggleClass(self.calendarContainer, 'rangeMode', self.config.mode === 'range');
    toggleClass(self.calendarContainer, 'animate', self.config.animate === true);
    toggleClass(self.calendarContainer, 'multiMonth', self.config.showMonths > 1);
    self.calendarContainer.appendChild(fragment);
    const customAppend = self.config.appendTo !== undefined && self.config.appendTo.nodeType !== undefined;
    if (self.config.inline || self.config.static) {
      self.calendarContainer.classList.add(self.config.inline ? 'inline' : 'static');
      if (self.config.inline) {
        if (!customAppend && self.element.parentNode) {
          self.element.parentNode.insertBefore(self.calendarContainer, self._input.nextSibling);
        } else if (self.config.appendTo !== undefined) {
          self.config.appendTo.appendChild(self.calendarContainer);
        }
      }
      if (self.config.static) {
        const wrapper = createElement('div', 'flatpickr-wrapper');
        if (self.element.parentNode) {
          self.element.parentNode.insertBefore(wrapper, self.element);
        }
        wrapper.appendChild(self.element);
        if (self.altInput) {
          wrapper.appendChild(self.altInput);
        }
        wrapper.appendChild(self.calendarContainer);
      }
    }
    if (!self.config.static && !self.config.inline) {
      (self.config.appendTo !== undefined ? self.config.appendTo : window.document.body).appendChild(self.calendarContainer);
    }
  }

  function createDay (className, date, _dayNumber, i) {
    const dateIsEnabled = isEnabled(date, true);
    const dayElement = createElement('span', className, date.getDate().toString());
    dayElement.dateObj = date;
    dayElement.$i = i;
    dayElement.setAttribute('aria-label', self.formatDate(date, self.config.ariaDateFormat));
    if (!className.includes('hidden') && compareDates(date, self.now) === 0) {
      self.todayDateElem = dayElement;
      dayElement.classList.add('today');
      dayElement.setAttribute('aria-current', 'date');
    }
    if (dateIsEnabled) {
      dayElement.tabIndex = -1;
      if (isDateSelected(date)) {
        dayElement.classList.add('selected');
        self.selectedDateElem = dayElement;
        if (self.config.mode === 'range') {
          toggleClass(dayElement, 'startRange', self.selectedDates[0] && compareDates(date, self.selectedDates[0], true) === 0);
          toggleClass(dayElement, 'endRange', self.selectedDates[1] && compareDates(date, self.selectedDates[1], true) === 0);
          if (className === 'nextMonthDay') {
            dayElement.classList.add('inRange');
          }
        }
      }
    } else {
      dayElement.classList.add('flatpickr-disabled');
    }
    if (self.config.mode === 'range') {
      if (isDateInRange(date) && !isDateSelected(date)) {
        dayElement.classList.add('inRange');
      }
    }
    if (self.weekNumbers && self.config.showMonths === 1 && className !== 'prevMonthDay' && i % 7 === 6) {
      self.weekNumbers.insertAdjacentHTML('beforeend', '<span class=\'flatpickr-day\'>' + self.config.getWeek(date) + '</span>');
    }
    triggerEvent('onDayCreate', dayElement);
    return dayElement;
  }

  function focusOnDayElem (targetNode) {
    targetNode.focus();
    if (self.config.mode === 'range') {
      onMouseOver(targetNode);
    }
  }

  function getFirstAvailableDay (delta) {
    const startMonth = delta > 0 ? 0 : self.config.showMonths - 1;
    const endMonth = delta > 0 ? self.config.showMonths : -1;
    for (let m = startMonth; m !== endMonth; m += delta) {
      const month = self.daysContainer.children[m];
      const startIndex = delta > 0 ? 0 : month.children.length - 1;
      const endIndex = delta > 0 ? month.children.length : -1;
      for (let i = startIndex; i !== endIndex; i += delta) {
        const c = month.children[i];
        if (!c.className.includes('hidden') && isEnabled(c.dateObj)) {
          return c;
        }
      }
    }
    return undefined;
  }

  function getNextAvailableDay (current, delta) {
    const givenMonth = !current.className.includes('Month') ? current.dateObj.getMonth() : self.currentMonth;
    const endMonth = delta > 0 ? self.config.showMonths : -1;
    const loopDelta = delta > 0 ? 1 : -1;
    for (let m = givenMonth - self.currentMonth; m !== endMonth; m += loopDelta) {
      const month = self.daysContainer.children[m];
      const startIndex = givenMonth - self.currentMonth === m ? current.$i + delta : delta < 0 ? month.children.length - 1 : 0;
      const numMonthDays = month.children.length;
      for (let i = startIndex; i >= 0 && i < numMonthDays && i !== (delta > 0 ? numMonthDays : -1); i += loopDelta) {
        const c = month.children[i];
        if (!c.className.includes('hidden') && isEnabled(c.dateObj) && Math.abs(current.$i - i) >= Math.abs(delta)) {
          return focusOnDayElem(c);
        }
      }
    }
    self.changeMonth(loopDelta);
    focusOnDay(getFirstAvailableDay(loopDelta), 0);
    return undefined;
  }

  function focusOnDay (current, offset) {
    const activeElement = getClosestActiveElement();
    const dayFocused = isInView(activeElement || document.body);
    const startElem = current !== undefined ? current : dayFocused ? activeElement : self.selectedDateElem !== undefined && isInView(self.selectedDateElem) ? self.selectedDateElem : self.todayDateElem !== undefined && isInView(self.todayDateElem) ? self.todayDateElem : getFirstAvailableDay(offset > 0 ? 1 : -1);
    if (startElem === undefined) {
      self._input.focus();
    } else if (!dayFocused) {
      focusOnDayElem(startElem);
    } else {
      getNextAvailableDay(startElem, offset);
    }
  }

  function buildMonthDays (year, month) {
    const firstOfMonth = (new Date(year, month, 1).getDay() - self.l10n.firstDayOfWeek + 7) % 7;
    const prevMonthDays = self.utils.getDaysInMonth((month - 1 + 12) % 12, year);
    const daysInMonth = self.utils.getDaysInMonth(month, year);
    const days = window.document.createDocumentFragment();
    const isMultiMonth = self.config.showMonths > 1;
    const prevMonthDayClass = isMultiMonth ? 'prevMonthDay hidden' : 'prevMonthDay';
    const nextMonthDayClass = isMultiMonth ? 'nextMonthDay hidden' : 'nextMonthDay';
    let dayNumber = prevMonthDays + 1 - firstOfMonth;
    let dayIndex = 0;
    for (; dayNumber <= prevMonthDays; dayNumber++, dayIndex++) {
      days.appendChild(createDay('flatpickr-day ' + prevMonthDayClass, new Date(year, month - 1, dayNumber), dayNumber, dayIndex));
    }
    for (dayNumber = 1; dayNumber <= daysInMonth; dayNumber++, dayIndex++) {
      days.appendChild(createDay('flatpickr-day', new Date(year, month, dayNumber), dayNumber, dayIndex));
    }
    for (let dayNum = daysInMonth + 1; dayNum <= 42 - firstOfMonth && (self.config.showMonths === 1 || dayIndex % 7 !== 0); dayNum++, dayIndex++) {
      days.appendChild(createDay('flatpickr-day ' + nextMonthDayClass, new Date(year, month + 1, dayNum % daysInMonth), dayNum, dayIndex));
    }
    const dayContainer = createElement('div', 'dayContainer');
    dayContainer.appendChild(days);
    return dayContainer;
  }

  function buildDays () {
    if (self.daysContainer === undefined) {
      return;
    }
    clearNode(self.daysContainer);
    if (self.weekNumbers) {
      clearNode(self.weekNumbers);
    }
    const frag = document.createDocumentFragment();
    for (let i = 0; i < self.config.showMonths; i++) {
      const d = new Date(self.currentYear, self.currentMonth, 1);
      d.setMonth(self.currentMonth + i);
      frag.appendChild(buildMonthDays(d.getFullYear(), d.getMonth()));
    }
    self.daysContainer.appendChild(frag);
    self.days = self.daysContainer.firstChild;
    if (self.config.mode === 'range' && self.selectedDates.length === 1) {
      onMouseOver();
    }
  }

  function buildMonthSwitch () {
    if (self.config.showMonths > 1 || self.config.monthSelectorType !== 'dropdown') {
      return;
    }
    const shouldBuildMonth = function (month) {
      if (self.config.minDate !== undefined && self.currentYear === self.config.minDate.getFullYear() && month < self.config.minDate.getMonth()) {
        return false;
      }
      return !(self.config.maxDate !== undefined && self.currentYear === self.config.maxDate.getFullYear() && month > self.config.maxDate.getMonth());
    };
    self.monthsDropdownContainer.tabIndex = -1;
    self.monthsDropdownContainer.innerHTML = '';

    self.currentMonthElementWrapper = createElement('div', 'flatpickr-monthDropdown-current-month-wrapper');
    self.currentMonthElement = createElement('div', 'flatpickr-monthDropdown-current-month');
    self.currentMonthElementArrow = createElement('span', 'flatpickr-monthDropdown-current-month-arrow');

    self.currentMonthElement.textContent = monthToStr(self.currentMonth, self.config.shorthandCurrentMonth, self.l10n);
    self.currentMonthElementArrow.innerHTML = self.isMonthPickerOpen ? self.config.upArrow : self.config.downArrow;

    self.currentMonthElementWrapper.appendChild(self.currentMonthElement);
    self.currentMonthElementWrapper.appendChild(self.currentMonthElementArrow);

    bind(self.currentMonthElementWrapper, 'click', toggleMonthPicker);

    self.monthsWrapper = createElement('ul', 'flatpickr-monthDropdown-month-wrapper');

    for (let i = 0; i < 12; i++) {
      if (!shouldBuildMonth(i)) {
        continue;
      }
      const month = createElement('li', 'flatpickr-monthDropdown-month');
      month.setAttribute('data-value', new Date(self.currentYear, i).getMonth().toString());
      month.textContent = monthToStr(i, self.config.shorthandCurrentMonth, self.l10n);
      month.tabIndex = -1;
      if (self.currentMonth === i) {
        month.classList.add('selected');
      }
      month.addEventListener('click', function (e) {
        const target = getEventTarget(e);
        const selectedMonth = parseInt(target.getAttribute('data-value'), 10);
        self.changeMonth(selectedMonth - self.currentMonth);
        triggerEvent('onMonthChange');
        self.closeMonthPicker();

        self.monthsWrapper.children.forEach((child) => {
          if (child.classList.contains('selected')) {
            child.classList.remove('selected');
          }
          if (self.currentMonth === parseInt(child.getAttribute('data-value'), 10)) {
            child.classList.add('selected');
          }
        });
      });
      self.monthsWrapper.appendChild(month);
    }
    self.monthsDropdownContainer.appendChild(self.currentMonthElementWrapper);
    self.monthsDropdownContainer.appendChild(self.monthsWrapper);
  }

  function buildMonth () {
    const container = createElement('div', 'flatpickr-month');
    const monthNavFragment = window.document.createDocumentFragment();
    let monthElement;
    if (self.config.showMonths > 1 || self.config.monthSelectorType === 'static') {
      monthElement = createElement('span', 'cur-month');
    } else {
      self.monthsDropdownContainer = createElement('div', 'flatpickr-monthDropdown-months');
      self.monthsDropdownContainer.setAttribute('aria-label', self.l10n.monthAriaLabel);

      bind(self.monthsDropdownContainer, 'change', function (e) {
        const target = getEventTarget(e);
        const selectedMonth = parseInt(target.value, 10);
        self.changeMonth(selectedMonth - self.currentMonth);
        triggerEvent('onMonthChange');
      });

      buildMonthSwitch();
      monthElement = self.monthsDropdownContainer;
    }
    const yearInput = createNumberInput('cur-year', { tabindex: '-1', upArrow: self.config.upArrow, downArrow: self.config.downArrow });
    const yearElement = yearInput.getElementsByTagName('input')[0];
    yearElement.setAttribute('aria-label', self.l10n.yearAriaLabel);
    if (self.config.minDate) {
      yearElement.setAttribute('min', self.config.minDate.getFullYear().toString());
    }
    if (self.config.maxDate) {
      yearElement.setAttribute('max', self.config.maxDate.getFullYear().toString());
      yearElement.disabled = !!self.config.minDate && self.config.minDate.getFullYear() === self.config.maxDate.getFullYear();
    }
    const currentMonth = createElement('div', 'flatpickr-current-month');
    currentMonth.appendChild(yearInput);
    currentMonth.appendChild(monthElement);
    monthNavFragment.appendChild(currentMonth);
    container.appendChild(monthNavFragment);
    return {
      container, yearElement, monthElement,
    };
  }

  function buildMonths () {
    clearNode(self.monthNav);
    self.monthNav.appendChild(self.prevMonthNav);
    if (self.config.showMonths) {
      self.yearElements = [];
      self.monthElements = [];
    }
    for (let m = self.config.showMonths; m--;) {
      const month = buildMonth();
      self.yearElements.push(month.yearElement);
      self.monthElements.push(month.monthElement);
      self.monthNav.appendChild(month.container);
    }
    self.monthNav.appendChild(self.nextMonthNav);
  }

  function buildMonthNav () {
    self.monthNav = createElement('div', 'flatpickr-months');
    self.yearElements = [];
    self.monthElements = [];
    self.prevMonthNav = createElement('span', 'flatpickr-prev-month');
    self.prevMonthNav.innerHTML = self.config.prevArrow;
    self.nextMonthNav = createElement('span', 'flatpickr-next-month');
    self.nextMonthNav.innerHTML = self.config.nextArrow;
    buildMonths();
    Object.defineProperty(self, '_hidePrevMonthArrow', {
      get () {
        return self.__hidePrevMonthArrow;
      },
      set (bool) {
        if (self.__hidePrevMonthArrow !== bool) {
          toggleClass(self.prevMonthNav, 'flatpickr-disabled', bool);
          self.__hidePrevMonthArrow = bool;
        }
      },
    });
    Object.defineProperty(self, '_hideNextMonthArrow', {
      get () {
        return self.__hideNextMonthArrow;
      },
      set (bool) {
        if (self.__hideNextMonthArrow !== bool) {
          toggleClass(self.nextMonthNav, 'flatpickr-disabled', bool);
          self.__hideNextMonthArrow = bool;
        }
      },
    });
    self.currentYearElement = self.yearElements[0];
    updateNavigationCurrentMonth();
    return self.monthNav;
  }

  function buildTime () {
    self.calendarContainer.classList.add('hasTime');
    if (self.config.noCalendar) {
      self.calendarContainer.classList.add('noCalendar');
    }
    const defaults = getDefaultHours(self.config);
    self.timeContainer = createElement('div', 'flatpickr-time');
    self.timeContainer.tabIndex = -1;
    const separator = createElement('span', 'flatpickr-time-separator', ':');
    const hourInput = createNumberInput('flatpickr-hour', {
      'aria-label': self.l10n.hourAriaLabel,
    });
    self.hourElement = hourInput.getElementsByTagName('input')[0];
    const minuteInput = createNumberInput('flatpickr-minute', {
      'aria-label': self.l10n.minuteAriaLabel,
    });
    self.minuteElement = minuteInput.getElementsByTagName('input')[0];
    self.hourElement.tabIndex = self.minuteElement.tabIndex = -1;
    self.hourElement.value = pad(self.latestSelectedDateObj ? self.latestSelectedDateObj.getHours() : self.config.time_24hr ? defaults.hours : military2ampm(defaults.hours));
    self.minuteElement.value = pad(self.latestSelectedDateObj ? self.latestSelectedDateObj.getMinutes() : defaults.minutes);
    self.hourElement.setAttribute('step', self.config.hourIncrement.toString());
    self.minuteElement.setAttribute('step', self.config.minuteIncrement.toString());
    self.hourElement.setAttribute('min', self.config.time_24hr ? '0' : '1');
    self.hourElement.setAttribute('max', self.config.time_24hr ? '23' : '12');
    self.hourElement.setAttribute('maxlength', '2');
    self.minuteElement.setAttribute('min', '0');
    self.minuteElement.setAttribute('max', '59');
    self.minuteElement.setAttribute('maxlength', '2');
    self.timeContainer.appendChild(hourInput);
    self.timeContainer.appendChild(separator);
    self.timeContainer.appendChild(minuteInput);
    if (self.config.time_24hr) {
      self.timeContainer.classList.add('time24hr');
    }
    if (self.config.enableSeconds) {
      self.timeContainer.classList.add('hasSeconds');
      const secondInput = createNumberInput('flatpickr-second');
      self.secondElement = secondInput.getElementsByTagName('input')[0];
      self.secondElement.value = pad(self.latestSelectedDateObj ? self.latestSelectedDateObj.getSeconds() : defaults.seconds);
      self.secondElement.setAttribute('step', self.minuteElement.getAttribute('step'));
      self.secondElement.setAttribute('min', '0');
      self.secondElement.setAttribute('max', '59');
      self.secondElement.setAttribute('maxlength', '2');
      self.timeContainer.appendChild(createElement('span', 'flatpickr-time-separator', ':'));
      self.timeContainer.appendChild(secondInput);
    }
    if (!self.config.time_24hr) {
      self.amPM = createElement('span', 'flatpickr-am-pm', self.l10n.amPM[int((self.latestSelectedDateObj ? self.hourElement.value : self.config.defaultHour) > 11)]);
      self.amPM.title = self.l10n.toggleTitle;
      self.amPM.tabIndex = -1;
      self.timeContainer.appendChild(self.amPM);
    }
    return self.timeContainer;
  }

  function buildWeekdays () {
    if (!self.weekdayContainer) {
      self.weekdayContainer = createElement('div', 'flatpickr-weekdays');
    } else {
      clearNode(self.weekdayContainer);
    }
    for (let i = self.config.showMonths; i--;) {
      const container = createElement('div', 'flatpickr-weekdaycontainer');
      self.weekdayContainer.appendChild(container);
    }
    updateWeekdays();
    return self.weekdayContainer;
  }

  function updateWeekdays () {
    if (!self.weekdayContainer) {
      return;
    }
    const firstDayOfWeek = self.l10n.firstDayOfWeek;
    let weekdays = __spreadArrays(self.l10n.weekdays.shorthand);
    if (firstDayOfWeek > 0 && firstDayOfWeek < weekdays.length) {
      weekdays = __spreadArrays(weekdays.splice(firstDayOfWeek, weekdays.length), weekdays.splice(0, firstDayOfWeek));
    }
    for (let i = self.config.showMonths; i--;) {
      self.weekdayContainer.children[i].innerHTML = '\n      <span class=\'flatpickr-weekday\'>\n        ' + weekdays.join('</span><span class=\'flatpickr-weekday\'>') + '\n      </span>\n      ';
    }
  }

  function buildWeeks () {
    self.calendarContainer.classList.add('hasWeeks');
    const weekWrapper = createElement('div', 'flatpickr-weekwrapper');
    weekWrapper.appendChild(createElement('span', 'flatpickr-weekday', self.l10n.weekAbbreviation));
    const weekNumbers = createElement('div', 'flatpickr-weeks');
    weekWrapper.appendChild(weekNumbers);
    return {
      weekWrapper, weekNumbers,
    };
  }

  function changeMonth (value, isOffset) {
    if (isOffset === undefined) {
      isOffset = true;
    }
    const delta = isOffset ? value : value - self.currentMonth;
    if ((delta < 0 && self._hidePrevMonthArrow === true) || (delta > 0 && self._hideNextMonthArrow === true)) {
      return;
    }
    self.currentMonth += delta;
    if (self.currentMonth < 0 || self.currentMonth > 11) {
      self.currentYear += self.currentMonth > 11 ? 1 : -1;
      self.currentMonth = (self.currentMonth + 12) % 12;
      triggerEvent('onYearChange');
      buildMonthSwitch();
    }
    buildDays();
    triggerEvent('onMonthChange');
    updateNavigationCurrentMonth();
    self.currentMonthElement.textContent = monthToStr(self.currentMonth, self.config.shorthandCurrentMonth, self.l10n);
  }

  function clear (triggerChangeEvent, toInitial) {
    if (triggerChangeEvent === undefined) {
      triggerChangeEvent = true;
    }
    if (toInitial === undefined) {
      toInitial = true;
    }
    self.input.value = '';
    if (self.altInput !== undefined) {
      self.altInput.value = '';
    }
    if (self.mobileInput !== undefined) {
      self.mobileInput.value = '';
    }
    self.selectedDates = [];
    self.latestSelectedDateObj = undefined;
    if (toInitial === true) {
      self.currentYear = self._initialDate.getFullYear();
      self.currentMonth = self._initialDate.getMonth();
    }
    if (self.config.enableTime === true) {
      const _a = getDefaultHours(self.config);
      const hours = _a.hours;
      const minutes = _a.minutes;
      const seconds = _a.seconds;
      setHours(hours, minutes, seconds);
    }
    self.redraw();
    if (triggerChangeEvent) {
      triggerEvent('onChange');
    }
  }

  function close () {
    self.isOpen = false;
    if (!self.isMobile) {
      if (self.calendarContainer !== undefined) {
        self.calendarContainer.classList.remove('open');
      }
      if (self._input !== undefined) {
        self._input.classList.remove('active');
      }
    }
    triggerEvent('onClose');
  }

  function closeMonthPicker () {
    self.isMonthPickerOpen = false;
    if (self.monthsWrapper !== undefined) {
      self.monthsWrapper.classList.remove('open');
    }
    if (self.monthsDropdownContainer !== undefined) {
      self.monthsDropdownContainer.classList.remove('open');
    }
    self.currentMonthElementArrow.innerHTML = self.isMonthPickerOpen ? self.config.upArrow : self.config.downArrow;
  }

  function destroy () {
    if (self.config !== undefined) {
      triggerEvent('onDestroy');
    }
    for (let i = self._handlers.length; i--;) {
      self._handlers[i].remove();
    }
    self._handlers = [];
    if (self.mobileInput) {
      if (self.mobileInput.parentNode) {
        self.mobileInput.parentNode.removeChild(self.mobileInput);
      }
      self.mobileInput = undefined;
    } else if (self.calendarContainer && self.calendarContainer.parentNode) {
      if (self.config.static && self.calendarContainer.parentNode) {
        const wrapper = self.calendarContainer.parentNode;
        wrapper.lastChild && wrapper.removeChild(wrapper.lastChild);
        if (wrapper.parentNode) {
          while (wrapper.firstChild) {
            wrapper.parentNode.insertBefore(wrapper.firstChild, wrapper);
          }
          wrapper.parentNode.removeChild(wrapper);
        }
      } else {
        self.calendarContainer.parentNode.removeChild(self.calendarContainer);
      }
    }
    if (self.altInput) {
      self.input.type = 'text';
      if (self.altInput.parentNode) {
        self.altInput.parentNode.removeChild(self.altInput);
      }
      delete self.altInput;
    }
    if (self.input) {
      self.input.type = self.input._type;
      self.input.classList.remove('flatpickr-input');
      self.input.removeAttribute('readonly');
    }
    ['_showTimeInput', 'latestSelectedDateObj', '_hideNextMonthArrow', '_hidePrevMonthArrow', '__hideNextMonthArrow', '__hidePrevMonthArrow', 'isMobile', 'isOpen', 'selectedDateElem', 'minDateHasTime', 'maxDateHasTime', 'days', 'daysContainer', '_input', '_positionElement', 'innerContainer', 'rContainer', 'monthNav', 'todayDateElem', 'calendarContainer', 'weekdayContainer', 'prevMonthNav', 'nextMonthNav', 'monthsDropdownContainer', 'currentMonthElementWrapper', 'currentMonthElement', 'currentYearElement', 'navigationCurrentMonth', 'selectedDateElem', 'config'].forEach(function (k) {
      try {
        delete self[k];
      } catch (_) {
        /* Could throw in strict mode */
      }
    });
  }

  function isCalendarElem (elem) {
    return self.calendarContainer.contains(elem);
  }

  function documentClick (e) {
    if (self.isOpen && !self.config.inline) {
      const eventTarget1 = getEventTarget(e);
      const isCalendarElement = isCalendarElem(eventTarget1);
      const isInput = eventTarget1 === self.input || eventTarget1 === self.altInput || self.element.contains(eventTarget1) || (e.path && e.path.indexOf && (~e.path.indexOf(self.input) || ~e.path.indexOf(self.altInput)));
      const lostFocus = !isInput && !isCalendarElement && !isCalendarElem(e.relatedTarget);
      const isIgnored = !self.config.ignoredFocusElements.some(function (elem) {
        return elem.contains(eventTarget1);
      });
      if (lostFocus && isIgnored) {
        if (self.config.allowInput) {
          self.setDate(self._input.value, false, self.config.altInput ? self.config.altFormat : self.config.dateFormat);
        }
        if (self.timeContainer !== undefined && self.minuteElement !== undefined && self.hourElement !== undefined && self.input.value !== '' && self.input.value !== undefined) {
          updateTime();
        }
        self.close();
        if (self.config && self.config.mode === 'range' && self.selectedDates.length === 1) {
          self.clear(false);
        }
      }
    }
  }

  function changeYear (newYear) {
    if (!newYear || (self.config.minDate && newYear < self.config.minDate.getFullYear()) || (self.config.maxDate && newYear > self.config.maxDate.getFullYear())) {
      return;
    }
    const newYearNum = newYear;
    const isNewYear = self.currentYear !== newYearNum;
    self.currentYear = newYearNum || self.currentYear;
    if (self.config.maxDate && self.currentYear === self.config.maxDate.getFullYear()) {
      self.currentMonth = Math.min(self.config.maxDate.getMonth(), self.currentMonth);
    } else if (self.config.minDate && self.currentYear === self.config.minDate.getFullYear()) {
      self.currentMonth = Math.max(self.config.minDate.getMonth(), self.currentMonth);
    }
    if (isNewYear) {
      self.redraw();
      triggerEvent('onYearChange');
      buildMonthSwitch();
    }
  }

  function isEnabled (date, timeless) {
    let _a;
    if (timeless === undefined) {
      timeless = true;
    }
    const dateToCheck = self.parseDate(date, undefined, timeless);
    if ((self.config.minDate && dateToCheck && compareDates(dateToCheck, self.config.minDate, timeless !== undefined ? timeless : !self.minDateHasTime) < 0) || (self.config.maxDate && dateToCheck && compareDates(dateToCheck, self.config.maxDate, timeless !== undefined ? timeless : !self.maxDateHasTime) > 0)) {
      return false;
    }
    if (!self.config.enable && self.config.disable.length === 0) {
      return true;
    }
    if (dateToCheck === undefined) {
      return false;
    }
    const bool = !!self.config.enable;
    const array = (_a = self.config.enable) !== null && _a !== undefined ? _a : self.config.disable;
    for (let i = 0, d; i < array.length; i++) {
      d = array[i];
      if (typeof d === 'function' && d(dateToCheck)) {
        return bool;
      } else if (d instanceof Date && dateToCheck !== undefined && d.getTime() === dateToCheck.getTime()) {
        return bool;
      } else if (typeof d === 'string') {
        const parsed = self.parseDate(d, undefined, true);
        return parsed && parsed.getTime() === dateToCheck.getTime() ? bool : !bool;
      } else if (typeof d === 'object' && dateToCheck !== undefined && d.from && d.to && dateToCheck.getTime() >= d.from.getTime() && dateToCheck.getTime() <= d.to.getTime()) {
        return bool;
      }
    }
    return !bool;
  }

  function isInView (elem) {
    if (self.daysContainer !== undefined) {
      return (!elem.className.includes('hidden') && !elem.className.includes('flatpickr-disabled') && self.daysContainer.contains(elem));
    }
    return false;
  }

  function onBlur (e) {
    const isInput = e.target === self._input;
    const valueChanged = self._input.value.trimEnd() !== getDateStr();
    if (isInput && valueChanged && !(e.relatedTarget && isCalendarElem(e.relatedTarget))) {
      self.setDate(self._input.value, true, e.target === self.altInput ? self.config.altFormat : self.config.dateFormat);
    }
  }

  function onKeyDown (e) {
    const eventTarget = getEventTarget(e);
    const isInput = self.config.wrap ? element.contains(eventTarget) : eventTarget === self._input;
    const allowInput = self.config.allowInput;
    const allowKeydown = self.isOpen && (!allowInput || !isInput);
    const allowInlineKeydown = self.config.inline && isInput && !allowInput;
    if (e.keyCode === 13 && isInput) {
      if (allowInput) {
        self.setDate(self._input.value, true, eventTarget === self.altInput ? self.config.altFormat : self.config.dateFormat);
        self.close();
        return eventTarget.blur();
      } else {
        self.open();
      }
    } else if (isCalendarElem(eventTarget) || allowKeydown || allowInlineKeydown) {
      const isTimeObj = !!self.timeContainer && self.timeContainer.contains(eventTarget);
      switch (e.keyCode) {
        case 13:
          if (isTimeObj) {
            e.preventDefault();
            updateTime();
            focusAndClose();
          } else {
            selectDate(e);
          }
          break;
        case 27:
          e.preventDefault();
          focusAndClose();
          break;
        case 8:
        case 46:
          if (isInput && !self.config.allowInput) {
            e.preventDefault();
            self.clear();
          }
          break;
        case 37:
        case 39:
          if (!isTimeObj && !isInput) {
            e.preventDefault();
            const activeElement = getClosestActiveElement();
            if (self.daysContainer !== undefined && (allowInput === false || (activeElement && isInView(activeElement)))) {
              const delta1 = e.keyCode === 39 ? 1 : -1;
              if (!e.ctrlKey) {
                focusOnDay(undefined, delta1);
              } else {
                e.stopPropagation();
                changeMonth(delta1);
                focusOnDay(getFirstAvailableDay(1), 0);
              }
            }
          } else if (self.hourElement) {
            self.hourElement.focus();
          }
          break;
        case 38:
        case 40:
          e.preventDefault();
          // eslint-disable-next-line no-var
          var delta = e.keyCode === 40 ? 1 : -1;
          if ((self.daysContainer && eventTarget.$i !== undefined) || eventTarget === self.input || eventTarget === self.altInput) {
            if (e.ctrlKey) {
              e.stopPropagation();
              changeYear(self.currentYear - delta);
              focusOnDay(getFirstAvailableDay(1), 0);
            } else if (!isTimeObj) {
              focusOnDay(undefined, delta * 7);
            }
          } else if (eventTarget === self.currentYearElement) {
            changeYear(self.currentYear - delta);
          } else if (self.config.enableTime) {
            if (!isTimeObj && self.hourElement) {
              self.hourElement.focus();
            }
            updateTime(e);
            self._debouncedChange();
          }
          break;
        case 9:
          if (isTimeObj) {
            const elems = [self.hourElement, self.minuteElement, self.secondElement, self.amPM]
              .concat(self.pluginElements)
              .filter(function (x) {
                return x;
              });
            const i = elems.indexOf(eventTarget);
            if (i !== -1) {
              const target = elems[i + (e.shiftKey ? -1 : 1)];
              e.preventDefault();
              (target || self._input).focus();
            }
          } else if (!self.config.noCalendar && self.daysContainer && self.daysContainer.contains(eventTarget) && e.shiftKey) {
            e.preventDefault();
            self._input.focus();
          }
          break;
        default:
          break;
      }
    }
    if (self.amPM !== undefined && eventTarget === self.amPM) {
      switch (e.key) {
        case self.l10n.amPM[0].charAt(0):
        case self.l10n.amPM[0].charAt(0).toLowerCase():
          self.amPM.textContent = self.l10n.amPM[0];
          setHoursFromInputs();
          updateValue();
          break;
        case self.l10n.amPM[1].charAt(0):
        case self.l10n.amPM[1].charAt(0).toLowerCase():
          self.amPM.textContent = self.l10n.amPM[1];
          setHoursFromInputs();
          updateValue();
          break;
      }
    }
    if (isInput || isCalendarElem(eventTarget)) {
      triggerEvent('onKeyDown', e);
    }
  }

  function onMouseOver (elem, cellClass) {
    if (cellClass === undefined) {
      cellClass = 'flatpickr-day';
    }
    if (self.selectedDates.length !== 1 || (elem && (!elem.classList.contains(cellClass) || elem.classList.contains('flatpickr-disabled')))) {
      return;
    }
    const hoverDate = elem ? elem.dateObj.getTime() : self.days.firstElementChild.dateObj.getTime();
    const initialDate = self.parseDate(self.selectedDates[0], undefined, true).getTime();
    const rangeStartDate = Math.min(hoverDate, self.selectedDates[0].getTime());
    const rangeEndDate = Math.max(hoverDate, self.selectedDates[0].getTime());
    let containsDisabled = false;
    let minRange = 0;
    let maxRange = 0;
    for (let t = rangeStartDate; t < rangeEndDate; t += duration.DAY) {
      if (!isEnabled(new Date(t), true)) {
        containsDisabled = containsDisabled || (t > rangeStartDate && t < rangeEndDate);
        if (t < initialDate && (!minRange || t > minRange)) {
          minRange = t;
        } else if (t > initialDate && (!maxRange || t < maxRange)) {
          maxRange = t;
        }
      }
    }
    const hoverableCells = Array.from(self.rContainer.querySelectorAll('*:nth-child(-n+' + self.config.showMonths + ') > .' + cellClass));
    hoverableCells.forEach(function (dayElem) {
      const date = dayElem.dateObj;
      const timestamp = date.getTime();
      const outOfRange = (minRange > 0 && timestamp < minRange) || (maxRange > 0 && timestamp > maxRange);
      if (outOfRange) {
        dayElem.classList.add('notAllowed');
        ['inRange', 'startRange', 'endRange'].forEach(function (c) {
          dayElem.classList.remove(c);
        });
        return;
      } else if (containsDisabled && !outOfRange) {
        return;
      }
      ['startRange', 'inRange', 'endRange', 'notAllowed'].forEach(function (c) {
        dayElem.classList.remove(c);
      });
      if (elem !== undefined) {
        elem.classList.add(hoverDate <= self.selectedDates[0].getTime() ? 'startRange' : 'endRange');
        if (initialDate < hoverDate && timestamp === initialDate) {
          dayElem.classList.add('startRange');
        } else if (initialDate > hoverDate && timestamp === initialDate) {
          dayElem.classList.add('endRange');
        }
        if (timestamp >= minRange && (maxRange === 0 || timestamp <= maxRange) && isBetween(timestamp, initialDate, hoverDate)) {
          dayElem.classList.add('inRange');
        }
      }
    });
  }

  function onResize () {
    if (self.isOpen && !self.config.static && !self.config.inline) {
      positionCalendar();
    }
  }

  function open (e, positionElement) {
    if (positionElement === undefined) {
      positionElement = self._positionElement;
    }
    if (self.isMobile === true) {
      if (e) {
        e.preventDefault();
        const eventTarget = getEventTarget(e);
        if (eventTarget) {
          eventTarget.blur();
        }
      }
      if (self.mobileInput !== undefined) {
        self.mobileInput.focus();
        self.mobileInput.click();
      }
      triggerEvent('onOpen');
      return;
    } else if (self._input.disabled || self.config.inline) {
      return;
    }
    const wasOpen = self.isOpen;
    self.isOpen = true;
    if (!wasOpen) {
      self.calendarContainer.classList.add('open');
      self._input.classList.add('active');
      triggerEvent('onOpen');
      positionCalendar(positionElement);
    }
    if (self.config.enableTime === true && self.config.noCalendar === true) {
      if (self.config.allowInput === false && (e === undefined || !self.timeContainer.contains(e.relatedTarget))) {
        setTimeout(function () {
          return self.hourElement.select();
        }, 50);
      }
    }
  }

  function openMonthPicker () {
    const wasOpen = self.isMonthPickerOpen;
    self.isMonthPickerOpen = true;
    if (!wasOpen) {
      self.monthsWrapper.classList.add('open');
      self.monthsDropdownContainer.classList.add('open');
    }
    self.currentMonthElementArrow.innerHTML = self.isMonthPickerOpen ? self.config.upArrow : self.config.downArrow;
  }

  function minMaxDateSetter (type) {
    return function (date) {
      const dateObj = (self.config['_' + type + 'Date'] = self.parseDate(date, self.config.dateFormat));
      const inverseDateObj = self.config['_' + (type === 'min' ? 'max' : 'min') + 'Date'];
      if (dateObj !== undefined) {
        self[type === 'min' ? 'minDateHasTime' : 'maxDateHasTime'] = dateObj.getHours() > 0 || dateObj.getMinutes() > 0 || dateObj.getSeconds() > 0;
      }
      if (self.selectedDates) {
        self.selectedDates = self.selectedDates.filter(function (d) {
          return isEnabled(d);
        });
        if (!self.selectedDates.length && type === 'min') {
          setHoursFromDate(dateObj);
        }
        updateValue();
      }
      if (self.daysContainer) {
        redraw();
        if (dateObj !== undefined) {
          self.currentYearElement[type] = dateObj.getFullYear().toString();
        } else {
          self.currentYearElement.removeAttribute(type);
        }
        self.currentYearElement.disabled = !!inverseDateObj && dateObj !== undefined && inverseDateObj.getFullYear() === dateObj.getFullYear();
      }
    };
  }

  function parseConfig () {
    const boolOpts = ['wrap', 'weekNumbers', 'allowInput', 'allowInvalidPreload', 'clickOpens', 'time_24hr', 'enableTime', 'noCalendar', 'altInput', 'shorthandCurrentMonth', 'inline', 'static', 'enableSeconds', 'disableMobile'];
    const userConfig = __assign(__assign({}, JSON.parse(JSON.stringify(element.dataset || {}))), instanceConfig);
    const formats = {};
    self.config.parseDate = userConfig.parseDate;
    self.config.formatDate = userConfig.formatDate;
    Object.defineProperty(self.config, 'enable', {
      get () {
        return self.config._enable;
      },
      set (dates) {
        self.config._enable = parseDateRules(dates);
      },
    });
    Object.defineProperty(self.config, 'disable', {
      get () {
        return self.config._disable;
      },
      set (dates) {
        self.config._disable = parseDateRules(dates);
      },
    });
    const timeMode = userConfig.mode === 'time';
    if (!userConfig.dateFormat && (userConfig.enableTime || timeMode)) {
      const defaultDateFormat = flatpickr.defaultConfig.dateFormat || defaultOptions.dateFormat;
      formats.dateFormat = userConfig.noCalendar || timeMode ? 'H:i' + (userConfig.enableSeconds ? ':S' : '') : defaultDateFormat + ' H:i' + (userConfig.enableSeconds ? ':S' : '');
    }
    if (userConfig.altInput && (userConfig.enableTime || timeMode) && !userConfig.altFormat) {
      const defaultAltFormat = flatpickr.defaultConfig.altFormat || defaultOptions.altFormat;
      formats.altFormat = userConfig.noCalendar || timeMode ? 'h:i' + (userConfig.enableSeconds ? ':S K' : ' K') : defaultAltFormat + (' h:i' + (userConfig.enableSeconds ? ':S' : '') + ' K');
    }
    Object.defineProperty(self.config, 'minDate', {
      get () {
        return self.config._minDate;
      },
      set: minMaxDateSetter('min'),
    });
    Object.defineProperty(self.config, 'maxDate', {
      get () {
        return self.config._maxDate;
      },
      set: minMaxDateSetter('max'),
    });
    const minMaxTimeSetter = function (type) {
      return function (val) {
        self.config[type === 'min' ? '_minTime' : '_maxTime'] = self.parseDate(val, 'H:i:S');
      };
    };
    Object.defineProperty(self.config, 'minTime', {
      get () {
        return self.config._minTime;
      },
      set: minMaxTimeSetter('min'),
    });
    Object.defineProperty(self.config, 'maxTime', {
      get () {
        return self.config._maxTime;
      },
      set: minMaxTimeSetter('max'),
    });
    if (userConfig.mode === 'time') {
      self.config.noCalendar = true;
      self.config.enableTime = true;
    }
    Object.assign(self.config, formats, userConfig);
    for (let i = 0; i < boolOpts.length; i++) {
      self.config[boolOpts[i]] = self.config[boolOpts[i]] === true || self.config[boolOpts[i]] === 'true';
    }
    HOOKS.filter(function (hook) {
      return self.config[hook] !== undefined;
    }).forEach(function (hook) {
      self.config[hook] = arrayify(self.config[hook] || []).map(bindToInstance);
    });
    self.isMobile = !self.config.disableMobile && !self.config.inline && self.config.mode === 'single' && !self.config.disable.length && !self.config.enable && !self.config.weekNumbers && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    for (let i = 0; i < self.config.plugins.length; i++) {
      const pluginConf = self.config.plugins[i](self) || {};
      for (const key in pluginConf) {
        if (HOOKS.includes(key)) {
          self.config[key] = arrayify(pluginConf[key])
            .map(bindToInstance)
            .concat(self.config[key]);
        } else if (typeof userConfig[key] === 'undefined') {
          self.config[key] = pluginConf[key];
        }
      }
    }
    if (!userConfig.altInputClass) {
      self.config.altInputClass = getInputElem().className + ' ' + self.config.altInputClass;
    }
    triggerEvent('onParseConfig');
  }

  function getInputElem () {
    return self.config.wrap ? element.querySelector('[data-input]') : element;
  }

  function setupLocale () {
    if (typeof self.config.locale !== 'object' && typeof flatpickr.l10ns[self.config.locale] === 'undefined') {
      self.config.errorHandler(new Error('flatpickr: invalid locale ' + self.config.locale));
    }
    self.l10n = __assign(__assign({}, flatpickr.l10ns.default), (typeof self.config.locale === 'object' ? self.config.locale : self.config.locale !== 'default' ? flatpickr.l10ns[self.config.locale] : undefined));
    tokenRegex.D = '(' + self.l10n.weekdays.shorthand.join('|') + ')';
    tokenRegex.l = '(' + self.l10n.weekdays.longhand.join('|') + ')';
    tokenRegex.M = '(' + self.l10n.months.shorthand.join('|') + ')';
    tokenRegex.F = '(' + self.l10n.months.longhand.join('|') + ')';
    tokenRegex.K = '(' + self.l10n.amPM[0] + '|' + self.l10n.amPM[1] + '|' + self.l10n.amPM[0].toLowerCase() + '|' + self.l10n.amPM[1].toLowerCase() + ')';
    const userConfig = __assign(__assign({}, instanceConfig), JSON.parse(JSON.stringify(element.dataset || {})));
    if (userConfig.time_24hr === undefined && flatpickr.defaultConfig.time_24hr === undefined) {
      self.config.time_24hr = self.l10n.time_24hr;
    }
    self.formatDate = createDateFormatter(self);
    self.parseDate = createDateParser({ config: self.config, l10n: self.l10n });
  }

  function positionCalendar (customPositionElement) {
    if (typeof self.config.position === 'function') {
      // eslint-disable-next-line no-void
      return void self.config.position(self, customPositionElement);
    }
    if (self.calendarContainer === undefined) {
      return;
    }
    triggerEvent('onPreCalendarPosition');
    const positionElement = customPositionElement || self._positionElement;
    const calendarHeight = Array.prototype.reduce.call(self.calendarContainer.children, function (acc, child) {
      return acc + child.offsetHeight;
    }, 0);
    const calendarWidth = self.calendarContainer.offsetWidth;
    const configPos = self.config.position.split(' ');
    const configPosVertical = configPos[0];
    const configPosHorizontal = configPos.length > 1 ? configPos[1] : null;
    const inputBounds = positionElement.getBoundingClientRect();
    const distanceFromBottom = window.innerHeight - inputBounds.bottom;
    const showOnTop = configPosVertical === 'above' || (configPosVertical !== 'below' && distanceFromBottom < calendarHeight && inputBounds.top > calendarHeight);
    const top = window.pageYOffset + inputBounds.top + (!showOnTop ? (positionElement.offsetHeight + 8) : (-calendarHeight - 16));
    if (self.config.inline) {
      return;
    }
    let left = window.pageXOffset + inputBounds.left;
    if (configPosHorizontal === 'center') {
      left -= (calendarWidth - inputBounds.width) / 2;
    } else if (configPosHorizontal === 'right') {
      left -= calendarWidth - inputBounds.width;
    }
    const right = window.document.body.offsetWidth - (window.pageXOffset + inputBounds.right);
    const rightMost = left + calendarWidth > window.document.body.offsetWidth;
    const centerMost = right + calendarWidth > window.document.body.offsetWidth;
    if (self.config.static) {
      return;
    }
    self.calendarContainer.style.top = top + 'px';
    if (!rightMost) {
      self.calendarContainer.style.left = left + 'px';
      self.calendarContainer.style.right = 'auto';
    } else if (!centerMost) {
      self.calendarContainer.style.left = 'auto';
      self.calendarContainer.style.right = right + 'px';
    } else {
      const doc = getDocumentStyleSheet();
      if (doc === undefined) {
        return;
      }
      const bodyWidth = window.document.body.offsetWidth;
      const centerLeft = Math.max(0, bodyWidth / 2 - calendarWidth / 2);
      const centerBefore = '.flatpickr-calendar.centerMost:before';
      const centerAfter = '.flatpickr-calendar.centerMost:after';
      const centerIndex = doc.cssRules.length;
      const centerStyle = '{left:' + inputBounds.left + 'px;right:auto;}';
      toggleClass(self.calendarContainer, 'rightMost', false);
      toggleClass(self.calendarContainer, 'centerMost', true);
      doc.insertRule(centerBefore + ',' + centerAfter + centerStyle, centerIndex);
      self.calendarContainer.style.left = centerLeft + 'px';
      self.calendarContainer.style.right = 'auto';
    }
  }

  function getDocumentStyleSheet () {
    let editableSheet = null;
    for (let i = 0; i < document.styleSheets.length; i++) {
      const sheet = document.styleSheets[i];
      if (!sheet.cssRules) {
        continue;
      }
      try {
        // eslint-disable-next-line no-unused-expressions
        sheet.cssRules;
      } catch (err) {
        continue;
      }
      editableSheet = sheet;
      break;
    }
    return editableSheet != null ? editableSheet : createStyleSheet();
  }

  function createStyleSheet () {
    const style = document.createElement('style');
    document.head.appendChild(style);
    return style.sheet;
  }

  function redraw () {
    if (self.config.noCalendar || self.isMobile) {
      return;
    }
    buildMonthSwitch();
    updateNavigationCurrentMonth();
    buildDays();
  }

  function focusAndClose () {
    self._input.focus();
    if (window.navigator.userAgent.includes('MSIE') || navigator.msMaxTouchPoints !== undefined) {
      setTimeout(self.close, 0);
    } else {
      self.close();
    }
  }

  function selectDate (e) {
    e.preventDefault();
    e.stopPropagation();
    const isSelectable = function (day) {
      return day.classList && day.classList.contains('flatpickr-day') && !day.classList.contains('flatpickr-disabled') && !day.classList.contains('notAllowed');
    };
    const t = findParent(getEventTarget(e), isSelectable);
    if (t === undefined) {
      return;
    }
    const target = t;
    const selectedDate = (self.latestSelectedDateObj = new Date(target.dateObj.getTime()));
    const shouldChangeMonth = (selectedDate.getMonth() < self.currentMonth || selectedDate.getMonth() > self.currentMonth + self.config.showMonths - 1) && self.config.mode !== 'range';
    self.selectedDateElem = target;
    if (self.config.mode === 'single') {
      self.selectedDates = [selectedDate];
    } else if (self.config.mode === 'multiple') {
      const selectedIndex = isDateSelected(selectedDate);
      if (selectedIndex) {
        self.selectedDates.splice(parseInt(selectedIndex), 1);
      } else {
        self.selectedDates.push(selectedDate);
      }
    } else if (self.config.mode === 'range') {
      if (self.selectedDates.length === 2) {
        self.clear(false, false);
      }
      self.latestSelectedDateObj = selectedDate;
      self.selectedDates.push(selectedDate);
      if (compareDates(selectedDate, self.selectedDates[0], true) !== 0) {
        self.selectedDates.sort(function (a, b) {
          return a.getTime() - b.getTime();
        });
      }
    }
    setHoursFromInputs();
    if (shouldChangeMonth) {
      const isNewYear = self.currentYear !== selectedDate.getFullYear();
      self.currentYear = selectedDate.getFullYear();
      self.currentMonth = selectedDate.getMonth();
      if (isNewYear) {
        triggerEvent('onYearChange');
        buildMonthSwitch();
      }
      triggerEvent('onMonthChange');
    }
    updateNavigationCurrentMonth();
    buildDays();
    updateValue();
    if (!shouldChangeMonth && self.config.mode !== 'range' && self.config.showMonths === 1) {
      focusOnDayElem(target);
    } else if (self.selectedDateElem !== undefined && self.hourElement === undefined) {
      self.selectedDateElem && self.selectedDateElem.focus();
    }
    if (self.hourElement !== undefined) {
      self.hourElement !== undefined && self.hourElement.focus();
    }
    if (self.config.closeOnSelect) {
      const single = self.config.mode === 'single' && !self.config.enableTime;
      const range = self.config.mode === 'range' && self.selectedDates.length === 2 && !self.config.enableTime;
      if (single || range) {
        focusAndClose();
      }
    }
    triggerChange();
  }

  const CALLBACKS = {
    locale: [setupLocale, updateWeekdays],
    showMonths: [buildMonths, setCalendarWidth, buildWeekdays],
    minDate: [jumpToDate],
    maxDate: [jumpToDate],
    positionElement: [updatePositionElement],
    clickOpens: [function () {
      if (self.config.clickOpens === true) {
        bind(self._input, 'focus', self.open);
        bind(self._input, 'click', self.open);
      } else {
        self._input.removeEventListener('focus', self.open);
        self._input.removeEventListener('click', self.open);
      }
    }],
  };

  function set (option, value) {
    if (option !== null && typeof option === 'object') {
      Object.assign(self.config, option);
      for (const key in option) {
        if (CALLBACKS[key] !== undefined) {
          CALLBACKS[key].forEach(function (x) {
            return x();
          });
        }
      }
    } else {
      self.config[option] = value;
      if (CALLBACKS[option] !== undefined) {
        CALLBACKS[option].forEach(function (x) {
          return x();
        });
      } else if (HOOKS.includes(option)) {
        self.config[option] = arrayify(value);
      }
    }
    self.redraw();
    updateValue(true);
  }

  function setSelectedDate (inputDate, format) {
    let dates = [];
    if (Array.isArray(inputDate)) {
      dates = inputDate.map(function (d) {
        return self.parseDate(d, format);
      });
    } else if (inputDate instanceof Date || typeof inputDate === 'number') {
      dates = [self.parseDate(inputDate, format)];
    } else if (typeof inputDate === 'string') {
      switch (self.config.mode) {
        case 'single':
        case 'time':
          dates = [self.parseDate(inputDate, format)];
          break;
        case 'multiple':
          dates = inputDate
            .split(self.config.conjunction)
            .map(function (date) {
              return self.parseDate(date, format);
            });
          break;
        case 'range':
          dates = inputDate
            .split(self.l10n.rangeSeparator)
            .map(function (date) {
              return self.parseDate(date, format);
            });
          break;
        default:
          break;
      }
    } else {
      self.config.errorHandler(new Error('Invalid date supplied: ' + JSON.stringify(inputDate)));
    }
    self.selectedDates = (self.config.allowInvalidPreload ? dates : dates.filter(function (d) {
      return d instanceof Date && isEnabled(d, false);
    }));
    if (self.config.mode === 'range') {
      self.selectedDates.sort(function (a, b) {
        return a.getTime() - b.getTime();
      });
    }
  }

  function setDate (date, triggerChange, format) {
    if (triggerChange === undefined) {
      triggerChange = false;
    }
    if (format === undefined) {
      format = self.config.dateFormat;
    }
    if ((date !== 0 && !date) || (Array.isArray(date) && date.length === 0)) {
      return self.clear(triggerChange);
    }
    setSelectedDate(date, format);
    self.latestSelectedDateObj = self.selectedDates[self.selectedDates.length - 1];
    self.redraw();
    jumpToDate(undefined, triggerChange);
    setHoursFromDate();
    if (self.selectedDates.length === 0) {
      self.clear(false);
    }
    updateValue(triggerChange);
    if (triggerChange) {
      triggerEvent('onChange');
    }
  }

  function parseDateRules (arr) {
    return arr
      .slice()
      .map(function (rule) {
        if (typeof rule === 'string' || typeof rule === 'number' || rule instanceof Date) {
          return self.parseDate(rule, undefined, true);
        } else if (rule && typeof rule === 'object' && rule.from && rule.to) {
          return {
            from: self.parseDate(rule.from, undefined), to: self.parseDate(rule.to, undefined),
          };
        }
        return rule;
      })
      .filter(function (x) {
        return x;
      });
  }

  function setupDates () {
    self.selectedDates = [];
    self.now = self.parseDate(self.config.now) || new Date();
    const preloadedDate = self.config.defaultDate || ((self.input.nodeName === 'INPUT' || self.input.nodeName === 'TEXTAREA') && self.input.placeholder && self.input.value === self.input.placeholder ? null : self.input.value);
    if (preloadedDate) {
      setSelectedDate(preloadedDate, self.config.dateFormat);
    }
    self._initialDate = self.selectedDates.length > 0 ? self.selectedDates[0] : self.config.minDate && self.config.minDate.getTime() > self.now.getTime() ? self.config.minDate : self.config.maxDate && self.config.maxDate.getTime() < self.now.getTime() ? self.config.maxDate : self.now;
    self.currentYear = self._initialDate.getFullYear();
    self.currentMonth = self._initialDate.getMonth();
    if (self.selectedDates.length > 0) {
      self.latestSelectedDateObj = self.selectedDates[0];
    }
    if (self.config.minTime !== undefined) {
      self.config.minTime = self.parseDate(self.config.minTime, 'H:i');
    }
    if (self.config.maxTime !== undefined) {
      self.config.maxTime = self.parseDate(self.config.maxTime, 'H:i');
    }
    self.minDateHasTime = !!self.config.minDate && (self.config.minDate.getHours() > 0 || self.config.minDate.getMinutes() > 0 || self.config.minDate.getSeconds() > 0);
    self.maxDateHasTime = !!self.config.maxDate && (self.config.maxDate.getHours() > 0 || self.config.maxDate.getMinutes() > 0 || self.config.maxDate.getSeconds() > 0);
  }

  function setupInputs () {
    self.input = getInputElem();
    if (!self.input) {
      self.config.errorHandler(new Error('Invalid input element specified'));
      return;
    }
    self.input._type = self.input.type;
    self.input.type = 'text';
    self.input.classList.add('flatpickr-input');
    self._input = self.input;
    if (self.config.altInput) {
      self.altInput = createElement(self.input.nodeName, self.config.altInputClass);
      self._input = self.altInput;
      self.altInput.placeholder = self.input.placeholder;
      self.altInput.disabled = self.input.disabled;
      self.altInput.required = self.input.required;
      self.altInput.tabIndex = self.input.tabIndex;
      self.altInput.type = 'text';
      self.input.setAttribute('type', 'hidden');
      if (!self.config.static && self.input.parentNode) {
        self.input.parentNode.insertBefore(self.altInput, self.input.nextSibling);
      }
    }
    if (!self.config.allowInput) {
      self._input.setAttribute('readonly', 'readonly');
    }
    updatePositionElement();
  }

  function updatePositionElement () {
    self._positionElement = self.config.positionElement || self._input;
  }

  function setupMobile () {
    const inputType = self.config.enableTime ? self.config.noCalendar ? 'time' : 'datetime-local' : 'date';
    const desktopInput = self.input;

    self.mobileInput = createElement('input', desktopInput.className + ' flatpickr-mobile');
    self.mobileInput.tabIndex = 1;
    self.mobileInput.type = inputType;
    self.mobileInput.disabled = self.input.disabled;
    self.mobileInput.required = self.input.required;
    self.mobileInput.placeholder = self.input.getAttribute('placeholder');
    self.mobileInput.id = desktopInput.id;
    self.mobileInput.setAttribute('aria-describedby', desktopInput.getAttribute('aria-describedby'));
    self.mobileFormatStr = inputType === 'datetime-local' ? 'Y-m-d\\TH:i:S' : inputType === 'date' ? 'Y-m-d' : 'H:i:S';
    if (self.selectedDates.length > 0) {
      self.mobileInput.defaultValue = self.mobileInput.value = self.formatDate(self.selectedDates[0], self.mobileFormatStr);
    }
    if (self.config.minDate) {
      self.mobileInput.min = self.formatDate(self.config.minDate, 'Y-m-d');
    }
    if (self.config.maxDate) {
      self.mobileInput.max = self.formatDate(self.config.maxDate, 'Y-m-d');
    }
    if (self.input.getAttribute('step')) {
      self.mobileInput.step = String(self.input.getAttribute('step'));
    }
    self.input.type = 'hidden';
    if (self.altInput !== undefined) {
      self.altInput.type = 'hidden';
    }
    try {
      if (desktopInput.parentNode) {
        desktopInput.parentNode.insertBefore(self.mobileInput, desktopInput.nextSibling);
        desktopInput.style.display = 'none';
        desktopInput.parentNode.removeChild(desktopInput);
      }
    } catch (_a) {
      /* Could throw some error */
    }
    bind(self.mobileInput, 'change', function (e) {
      self.setDate(getEventTarget(e).value, false, self.mobileFormatStr);
      triggerEvent('onChange');
      triggerEvent('onClose');
    });
  }

  function toggle (e) {
    if (self.isOpen === true) {
      return self.close();
    }
    self.open(e);
  }

  function toggleMonthPicker () {
    if (self.isMonthPickerOpen === true) {
      return self.closeMonthPicker();
    }
    self.openMonthPicker();
  }

  function triggerEvent (event, data) {
    if (self.config === undefined) {
      return;
    }
    const hooks = self.config[event];
    if (hooks !== undefined && hooks.length > 0) {
      for (let i = 0; hooks[i] && i < hooks.length; i++) {
        hooks[i](self.selectedDates, self.input.value, self, data);
      }
    }
    if (event === 'onChange') {
      self.input.dispatchEvent(createEvent('change'));
      self.input.dispatchEvent(createEvent('input'));
    }
  }

  function createEvent (name) {
    const e = document.createEvent('Event');
    e.initEvent(name, true, true);
    return e;
  }

  function isDateSelected (date) {
    for (let i = 0; i < self.selectedDates.length; i++) {
      const selectedDate = self.selectedDates[i];
      if (selectedDate instanceof Date && compareDates(selectedDate, date) === 0) {
        return '' + i;
      }
    }
    return false;
  }

  function isDateInRange (date) {
    if (self.config.mode !== 'range' || self.selectedDates.length < 2) {
      return false;
    }
    return (compareDates(date, self.selectedDates[0]) >= 0 && compareDates(date, self.selectedDates[1]) <= 0);
  }

  function updateNavigationCurrentMonth () {
    if (self.config.noCalendar || self.isMobile || !self.monthNav) {
      return;
    }
    self.yearElements.forEach(function (yearElement, i) {
      const d = new Date(self.currentYear, self.currentMonth, 1);
      d.setMonth(self.currentMonth + i);
      if (self.config.showMonths > 1 || self.config.monthSelectorType === 'static') {
        self.monthElements[i].textContent = monthToStr(d.getMonth(), self.config.shorthandCurrentMonth, self.l10n) + ' ';
      } else {
        self.monthsDropdownContainer.value = d.getMonth().toString();
      }
      yearElement.value = d.getFullYear().toString();
    });
    self._hidePrevMonthArrow = self.config.minDate !== undefined && (self.currentYear === self.config.minDate.getFullYear() ? self.currentMonth <= self.config.minDate.getMonth() : self.currentYear < self.config.minDate.getFullYear());
    self._hideNextMonthArrow = self.config.maxDate !== undefined && (self.currentYear === self.config.maxDate.getFullYear() ? self.currentMonth + 1 > self.config.maxDate.getMonth() : self.currentYear > self.config.maxDate.getFullYear());
  }

  function getDateStr (specificFormat) {
    const format = specificFormat || (self.config.altInput ? self.config.altFormat : self.config.dateFormat);
    return self.selectedDates
      .map(function (dObj) {
        return self.formatDate(dObj, format);
      })
      .filter(function (d, i, arr) {
        return self.config.mode !== 'range' || self.config.enableTime || arr.indexOf(d) === i;
      })
      .join(self.config.mode !== 'range' ? self.config.conjunction : self.l10n.rangeSeparator);
  }

  function updateValue (triggerChange) {
    if (triggerChange === undefined) {
      triggerChange = true;
    }
    if (self.mobileInput !== undefined && self.mobileFormatStr) {
      self.mobileInput.value = self.latestSelectedDateObj !== undefined ? self.formatDate(self.latestSelectedDateObj, self.mobileFormatStr) : '';
    }
    self.input.value = getDateStr(self.config.dateFormat);
    if (self.altInput !== undefined) {
      self.altInput.value = getDateStr(self.config.altFormat);
    }
    if (triggerChange !== false) {
      triggerEvent('onValueUpdate');
    }
  }

  function onMonthNavClick (e) {
    const eventTarget = getEventTarget(e);
    const isPrevMonth = self.prevMonthNav.contains(eventTarget);
    const isNextMonth = self.nextMonthNav.contains(eventTarget);

    if (isPrevMonth || isNextMonth) {
      changeMonth(isPrevMonth ? -1 : 1);
    } else if (self.yearElements.includes(eventTarget)) {
      eventTarget.select();
      // span HTMLNode || svg HTMLNode || path HTMLNode
    } else if (eventTarget.classList.contains('arrowUp') || (eventTarget.parentElement && eventTarget.parentElement.classList.contains('arrowUp')) || (eventTarget.parentElement && eventTarget.parentElement.parentElement && eventTarget.parentElement.parentElement.classList.contains('arrowUp'))) {
      self.changeYear(self.currentYear + 1);
      // span HTMLNode || svg HTMLNode || path HTMLNode
    } else if (eventTarget.classList.contains('arrowDown') || (eventTarget.parentElement && eventTarget.parentElement.classList.contains('arrowDown')) || (eventTarget.parentElement && eventTarget.parentElement.parentElement && eventTarget.parentElement.parentElement.classList.contains('arrowDown'))) {
      self.changeYear(self.currentYear - 1);
    }
  }

  function timeWrapper (e) {
    e.preventDefault();
    const isKeyDown = e.type === 'keydown';
    const eventTarget = getEventTarget(e);
    const input = eventTarget;
    if (self.amPM !== undefined && eventTarget === self.amPM) {
      self.amPM.textContent = self.l10n.amPM[int(self.amPM.textContent === self.l10n.amPM[0])];
    }
    const min = parseFloat(input.getAttribute('min'));
    const max = parseFloat(input.getAttribute('max'));
    const step = parseFloat(input.getAttribute('step'));
    const curValue = parseInt(input.value, 10);
    const delta = e.delta || (isKeyDown ? (e.which === 38 ? 1 : -1) : 0);
    let newValue = curValue + step * delta;
    if (typeof input.value !== 'undefined' && input.value.length === 2) {
      const isHourElem = input === self.hourElement;
      const isMinuteElem = input === self.minuteElement;
      if (newValue < min) {
        newValue = max + newValue + int(!isHourElem) + (int(isHourElem) && int(!self.amPM));
        if (isMinuteElem) {
          incrementNumInput(undefined, -1, self.hourElement);
        }
      } else if (newValue > max) {
        newValue = input === self.hourElement ? newValue - max - int(!self.amPM) : min;
        if (isMinuteElem) {
          incrementNumInput(undefined, 1, self.hourElement);
        }
      }
      if (self.amPM && isHourElem && (step === 1 ? newValue + curValue === 23 : Math.abs(newValue - curValue) > step)) {
        self.amPM.textContent = self.l10n.amPM[int(self.amPM.textContent === self.l10n.amPM[0])];
      }
      input.value = pad(newValue);
    }
  }

  init();
  return self;
}

function _flatpickr (nodeList, config) {
  const nodes = Array.prototype.slice
    .call(nodeList)
    .filter(function (x) {
      return x instanceof HTMLElement;
    });
  const instances = [];
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    try {
      if (node.getAttribute('data-fp-omit') !== null) {
        continue;
      }
      if (node._flatpickr !== undefined) {
        node._flatpickr.destroy();
        node._flatpickr = undefined;
      }
      node._flatpickr = FlatpickrInstance(node, config || {});
      instances.push(node._flatpickr);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
  return instances.length === 1 ? instances[0] : instances;
}

if (typeof HTMLElement !== 'undefined' && typeof HTMLCollection !== 'undefined' && typeof NodeList !== 'undefined') {
  HTMLCollection.prototype.flatpickr = NodeList.prototype.flatpickr = function (config) {
    return _flatpickr(this, config);
  };
  HTMLElement.prototype.flatpickr = function (config) {
    return _flatpickr([this], config);
  };
}
const flatpickr = function (selector, config) {
  if (typeof selector === 'string') {
    return _flatpickr(window.document.querySelectorAll(selector), config);
  } else if (selector instanceof Node) {
    return _flatpickr([selector], config);
  } else {
    return _flatpickr(selector, config);
  }
};
flatpickr.defaultConfig = {};
flatpickr.l10ns = {
  en: __assign({}, English), default: __assign({}, English),
};
flatpickr.localize = function (l10n) {
  flatpickr.l10ns.default = __assign(__assign({}, flatpickr.l10ns.default), l10n);
};
flatpickr.setDefaults = function (config) {
  flatpickr.defaultConfig = __assign(__assign({}, flatpickr.defaultConfig), config);
};
flatpickr.parseDate = createDateParser({});
flatpickr.formatDate = createDateFormatter({});
flatpickr.compareDates = compareDates;
if (typeof window !== 'undefined') {
  window.flatpickr = flatpickr;
}
export default flatpickr;
